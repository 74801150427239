const messages = {
  index: 'Home',
  news: 'News',
  paper: 'Paper',
  guide: 'User Guide',
  login: 'Login',
  register: 'Register'
}

export default messages
