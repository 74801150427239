const messages = {
  preProcessing: {
    title: 'Pre-processing',
    desc: ''
  },
  dataIntegration: {
    title: 'Data Merge',
    desc: ''
  },
  clustering: {
    title: 'Clustering',
    desc: ''
  },
  differentialExpression: {
    title: 'Differential Expression',
    desc: ''
  },
  cellTypeAnnotation: {
    title: 'Cell Type Annotation',
    desc: ''
  },
  geneSetEnrichment: {
    title: 'Gene Set Enrichment Analysis',
    desc: ''
  },
  trajectoryAnalysis: {
    title: 'Trajectory Analysis',
    desc: ''
  },
  cellCommunication: {
    title: 'Cell–Cell Communication',
    desc: 'cellular interactions between two cell types from single-cell transcriptomics data'
  },
  inferCNV: {
    title: 'Infer CNV',
    desc: 'InferCNV is used to explore tumor single cell RNA-Seq data to identify evidence for large-scale chromosomal copy number variations'
  },
  downstreamAnalysis: {
    name: 'USAIL(scRNA)',
    title: 'Downstream Analysis',
    intro: ''
  },
  advancedDownstreamAnalysis: {
    title: 'Advanced Downstream Analysis',
    desc: ''
  },
  convertFastq: {
    name: 'FastQ Converter',
    title: 'FastQ Converter',
    desc: '',
    intro: '单细胞原始测序数据生成表达矩阵的过程步骤包括：格式化reads并过滤嘈杂的细胞条形码, 分离样本, Mapping/pseudo-mapping到转录组, 去重UMIs并量化reads等。'
  },
  molecularSubtyping: {
    name: 'Molecular Subtyping',
    title: 'Molecular Subtyping',
    desc: '',
    intro: '对结直肠癌患者和乳腺癌患者组织转录组数据进行分析，可推断其属于的结直肠CMS分子分型和乳腺PAM50分子分型，用于精准医疗。'
  },
  VDJMiner: {
    name: 'VDJMiner',
    title: 'VDJMiner',
    desc: 'An interpretable artificial intelligence model to predict the diseases, as well as prognosis and treatment response. It was tested using immune repertoire data from COVID-19 patients.  (Designed for immune repertoire data, but it supports all tabular data analysis tasks, such as mining the association between the clinical information/biomarker and patients status.)',
    intro: 'An interpretable artificial intelligence model to predict the diseases, as well as prognosis and treatment response. It was tested using immune repertoire data from COVID-19 patients.  (Designed for immune repertoire data, but it supports all tabular data analysis tasks, such as mining the association between the clinical information/biomarker and patients status.)'
  },
  knowledgeGraph: {
    name: 'Knowledge Graph',
    title: 'Knowledge Graph',
    desc: '',
    intro: '融合多种AI技术的基因-细胞知识图谱。基于海量数据和前沿AI技术，实现知识提取，知识表征学习，知识图谱构建，提供基因-细胞层面的智能搜索，智能问答和智能推荐，致力于推动单细胞基因测序技术对科研和医疗的指导。'
  },
  spatialTranscriptomics: {
    name: 'Spatial omics database SODB',
    title: 'Spatial omics database SODB',
    desc: '',
    intro: 'SODB is a large-scale spatial omics database, including spatial transcriptomics, proteomics, metabolomics, genomics and multi-omics data. SODB provides an interactive user interface and efficient data management solutions, and unified data format to facilitate seamless integration with mainstream analysis tools. SODB also provides a novel data visualization method to quickly look at the overall molecular and spatial characteristics of each sample.'
  }
}

export default messages
