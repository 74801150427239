const messages = {
  cn: '中',
  en: 'EN',
  logout: 'logout',
  back: 'back',
  task: 'Task Management',
  theme: {
    light: 'light',
    dark: 'dark',
    auto: 'auto'
  },
  export: 'Export',
  selectExportProps: 'Select Properties to Export',
  upload: {
    uploadBtnText: 'Click to Upload',
    uploadTip: '{formats} file',
    fileSizeExceed: 'File size exceeded',
    single: 'Single File',
    multiple: 'Multiple Files',
    example: 'Example File'
  },
  taskTip: 'Task was created at {createTime}',
  logoText: 'SingleCell',
  confirm: 'Confirm',
  cancel: 'Cancel',
  reset: 'Reset',
  processData: 'Data Processing',
  processComplete: 'Processing Completed',
  plotFigures: 'Plot Figures',
  showResult: 'Result',
  downloadResult: 'Download Result',
  downloadParams: 'Download Parameters',
  downloadPictures: 'Download All Images',
  nextStep: 'Proceed to next step',
  placeText: 'select',
  plotText: 'Input plot parameters',
  qcResults: 'Quality Control Results'
}

export default messages
