import CommonMessage from '../../common/en/common'
import HeaderMessage from './header'
import BannerMessage from './banner'
import ModulesMessage from '../../common/en/modules'
// @ts-ignore
import elementEnLocale from 'element-ui/lib/locale/lang/en'

const messages = {
  back: 'back to list',
  prev: 'Previous',
  next: 'Next',
  relatedNews: 'Related News',
  moduleIntro: 'Modules',
  tryNow: 'Try Now',
  enter: 'Enter the Platform',
  desktopBrowser: 'Use desktop browser to experience',
  checkMore: 'Check More',
  paperResult: 'Papers',
  readAll: 'Read All',
  common: CommonMessage,
  header: HeaderMessage,
  banner: BannerMessage,
  modules: ModulesMessage,
  ...elementEnLocale
}

export default messages
